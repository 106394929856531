<template>
  <div class="modal-wrapper">
    <PModal
      :hide-header="true"
      :visible="!!(currentFlow && currentStage && currentModal)"
      centered
      no-close-on-backdrop
    >
      <component
        :is="currentModal"
        :featureFlags="features"
        v-bind="state"
        v-on="$listeners"
        @closeModal="closeModal"
        class="modal-wrapper__content"
      />
    </PModal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('modalManager');

export default {
  name: 'ModalWrapper',
  components: {
  },
  computed: {
    ...mapGetters([
      'currentModal',
      'currentFlow',
      'currentStage',
      'currentState',
    ]),
    features() {
      return this.$store.state.app.features;
    },
    state() {
      const attrs = this.$attrs || {};
      const currentState = this.currentState || {};
      return {
        ...currentState,
        ...attrs,
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions([
      'closeModal',
    ]),
    handleKeydown(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeModal();
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<style scoped>
.modal-wrapper__content {
  padding:16px 0px !important;
}

</style>
