<template>
  <div>
    <ReviewFundingRequest
      :typeName="requestTypeName"
      :subtypeName="requestSubtypeName"
      :title="title"
      :inlineDisplayFields="inlineDisplayFields"
      :blockDisplayFields="blockDisplayFields"
      :warningMessage="warningMessage"
      :openProcessingModal="openProcessingModal"
      v-on:confirmRequest="confirmRequest"
      v-on:back="back" />
    <PToast id="success-toast">
      {{`${requestTypeName} request has been submitted succesfully.`}}
    </PToast>
    <PToast id="error-toast" variant="danger" auto-hide-delay="7000">
      <div class="text-white">{{`An error has occurred, please contact operations for assistance.`}}</div>
    </PToast>
    <!-- TODO: add error wrapper here -->
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { DOCUMENT_SOURCE } from '../../../services/constants';
import { currencyFormatter, uploadDocuments } from '../../../helpers';
import {
  cancelFundingWorkflow,
  createWorkflow,
  fetchWorkflowBySystematicId,
  postComplexFundingRequest,
  postRequest,
  sendMessage,
  updateRequest,
  updateComplexFundingRequest,
  postInternalTransferRequest,
  postExternalTransferRequest,
  updateInternalTransferRequest,
  updateExternalTransferRequest,
} from '../../../services/api/FundingVersion2';
import { fetchWorkflowDetails } from '../../../services/api/Workflows';
import {
  FUNDING_DOCUMENT_TYPE,
  FUNDING_WORKFLOW,
  REQUEST_TYPES,
  REQUEST_SUBTYPE_IDS,
  isDefaultSubtype,
  isOneTimeRequest,
  canSetStagedTransfer,
} from '../../../services/helpers/funding';
import { getObject, requestConfirmation } from './confirmationHelper';

export default {
  name: 'ReviewRequest',
  props: {
  },
  data() {
    return {
      openProcessingModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'fundingFrequencies',
      'investmentAccts',
      'filteredToInvestmentAccts',
      'relatedClientAccts',
      'marginAccts',
      'rolloverSavings',
      'rolloverIncome',
      'bankAccts',
      'paymentMethods',
      'withholdingTaxTypes',
      'fundingTypes',
      'validatedRequest',
      'activeRequest',
      'withdrawalTypes',
      'editMode',
      'selectedRequestSubtype',
      'activeClient',
      'fundingDocuments',
      'currencyTypes',
      'warningMessage',
      'legalEntityTransfersFeatureFlag',
    ]),
    requestTypeName() {
      let type;
      if (this.validatedRequest && this.validatedRequest.type) {
        const funidngType = getObject(this.fundingTypes, this.validatedRequest.type);
        if (funidngType && funidngType.type) {
          switch (funidngType.type) {
            case 'PAC':
              type = 'Deposit';
              break;
            case 'SWP':
              type = 'Withdrawal';
              break;
            default:
              type = 'Funding';
          }
        }
      }
      return type;
    },
    requestSubtypeName() {
      return this.selectedRequestSubtype.subtype;
    },
    formattedAmount() {
      if (this.validatedRequest && this.validatedRequest.amount) {
        return currencyFormatter(this.validatedRequest.amount);
      }
      return '';
    },
    title() {
      if (isDefaultSubtype(this.selectedRequestSubtype)) {
        if (!isOneTimeRequest(this.validatedRequest)) {
          // recurring event
          const frequency = getObject(this.fundingFrequencies, this.validatedRequest.frequency).frequency;
          return `${this.formattedAmount} ${frequency} ${this.requestTypeName}`;
        }
        return `${this.formattedAmount} ${this.requestTypeName}`;
      }
      return `${this.formattedAmount} ${this.requestSubtypeName}`;
    },
    inlineDisplayFields() {
      const fields = [{
        label: 'For',
        value: this.activeClient.client.display_name,
      }];
      const toInvestmentAcctByType = [
        { id: REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_ACCOUNT_ROLLOVER, investAcctList: this.rolloverIncome },
        { id: REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_DIFFERENT_CLIENTS, investAcctList: this.relatedClientAccts },
        {
          id: REQUEST_SUBTYPE_IDS.WITHDRAWAL_RESP,
          investAcctList: this.filteredToInvestmentAccts?.length ? this.filteredToInvestmentAccts : this.investmentAccts,
        },
        {
          id: REQUEST_SUBTYPE_IDS.WITHDRAWAL_ONE_TIME_RRIF_SRRIF_LIF,
          investAcctList: this.filteredToInvestmentAccts?.length ? this.filteredToInvestmentAccts : this.investmentAccts,
        },
      ];

      const fromInvestmentAcctByType = [
        { id: REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_MARGIN_DRAW_DOWN, investAcctList: this.marginAccts },
        { id: REQUEST_SUBTYPE_IDS.INTERNAL_TRANSFER_ACCOUNT_ROLLOVER, investAcctList: this.rolloverSavings },
      ];

      const bankAcct = getObject(this.bankAccts, this.validatedRequest.bank_account);
      const fromBankAcct = getObject(this.bankAccts, this.validatedRequest.from_bank_account);
      const toBankAcct = getObject(this.bankAccts, this.validatedRequest.to_bank_account);
      const investAcct = getObject(this.investmentAccts, this.validatedRequest.investment_account);
      const toInvestAcct = getObject(
        this.getInvestmentAcctList(toInvestmentAcctByType),
        this.validatedRequest.to_investment_account,
      );
      const fromInvestAcct = getObject(
        this.getInvestmentAcctList(fromInvestmentAcctByType),
        this.validatedRequest.from_investment_account,
      );
      const externalTransferInvestAcct = getObject(this.investmentAccts, this.validatedRequest.investment_account_no);
      const paymentMethod = getObject(this.paymentMethods, this.validatedRequest.payment_method)?.description;
      const withdrawalType = getObject(this.withdrawalTypes, this.validatedRequest.withdrawal_type)?.description;
      const withholdingTaxType = getObject(this.withholdingTaxTypes, this.validatedRequest.withholding_tax_type)?.description;
      const currency = getObject(this.currencyTypes, this.validatedRequest.currency)?.iso_code;

      let frequency;
      if (this.validatedRequest.frequency) {
        frequency = getObject(this.fundingFrequencies, this.validatedRequest.frequency).frequency;
      }

      const confirmationFields = requestConfirmation({
        bankAcct,
        frequency,
        fromBankAcct,
        toBankAcct,
        investmentAcct: investAcct,
        subtype: this.selectedRequestSubtype.id,
        fromInvestmentAcct: fromInvestAcct,
        toInvestmentAcct: toInvestAcct,
        validatedRequest: this.validatedRequest,
        paymentMethod,
        withdrawalType,
        withholdingTaxType,
        fundingDocuments: this.fundingDocuments,
        currency,
        defaultRequestType: isDefaultSubtype(this.selectedRequestSubtype),
        withdrawalTypes: this.withdrawalTypes,
        selectedType: this.selectedRequestSubtype.type,
        externalTransferInvestAcct,
      });

      fields.push(...confirmationFields);
      if (this.validatedRequest.client_approval_required !== undefined) {
        fields.push({
          label: 'Client signature',
          value: this.validatedRequest.client_approval_required && !this.reducedAmount ? 'Required' : 'Not Required',
        });
      }
      return fields;
    },
    blockDisplayFields() {
      return [{
        label: 'Notes',
        value: this.validatedRequest.notes || '',
      }];
    },
    complexFundingPayload() {
      return { ...this.validatedRequest, client_id: this.activeClient.client.id };
    },
    documentSource() {
      return _.invert(DOCUMENT_SOURCE);
    },
    defaultSubtype() {
      return isDefaultSubtype(this.selectedRequestSubtype);
    },
    reducedAmount() {
      return this.editMode && this.validatedRequest.amount < this.activeRequest.amount;
    },
  },
  methods: {
    getInvestmentAcctList(investAcctByType) {
      if (investAcctByType.map((e) => e.id).includes(this.selectedRequestSubtype.id)) {
        return getObject(investAcctByType, this.selectedRequestSubtype.id).investAcctList;
      }
      return this.investmentAccts;
    },
    async initiateWorkflow(workflowId, workflowType) {
      const payload = {
        message: `workflow.${workflowType}.create.success`,
        data: {
          workflow_id: workflowId,
        },
      };
      await sendMessage({ body: payload });
    },
    async confirmRequest() {
      this.openProcessingModal = true;
      if (this.editMode) {
        if (this.activeRequest.funding_id) {
          this.updateFundingRequest(this.activeRequest.funding_id);
        } else { // currently only used by external transfers in
          await this.updateFundingRequestWithDocuments(this.validatedRequest.id);
          this.finalize();
        }
      } else {
        this.createFundingRequest();
      }
    },
    async updateFundingRequest(requestId) {
      const body = this.validatedRequest;
      body.request_id = requestId;
      try {
        const resp = await updateRequest({ body });
        await this.cancelWorkflow();
        const workflow = await createWorkflow({
          body: {
            funding_id: resp.id,
            reason: 'adjust',
            adjust_down: this.validatedRequest.amount < this.activeRequest.amount,
          },
        });
        await this.initiateWorkflow(workflow.id, 'funding');
        this.finalize();
      } catch (err) {
        this.openProcessingModal = false;
        this.$bvToast.show('error-toast');
      }
    },
    async updateFundingRequestWithDocuments(requestId) {
      const docIds = await this.uploadDocuments();

      const body = this.defaultSubtype ? this.validatedRequest : this.complexFundingPayload;
      if (docIds) body.document_ids = docIds;
      if (this.selectedRequestSubtype.type === REQUEST_TYPES.INTERNAL_TRANSFER) {
        body.internal_account_transfer_id = requestId;
        await updateInternalTransferRequest({ body });
      } else if (this.selectedRequestSubtype.type === REQUEST_TYPES.EXTERNAL_TRANSFER) {
        await updateExternalTransferRequest({ body }, requestId);
      } else {
        body.complex_funding_request_id = requestId;
        await updateComplexFundingRequest({ body });
      }
    },
    async createFundingRequest() {
      let resp;
      if (isDefaultSubtype(this.selectedRequestSubtype)) {
        resp = await postRequest({ body: this.validatedRequest });
      } else if (this.selectedRequestSubtype.type === REQUEST_TYPES.INTERNAL_TRANSFER) {
        resp = await postInternalTransferRequest({ body: this.complexFundingPayload });
      } else if (this.selectedRequestSubtype.type === REQUEST_TYPES.EXTERNAL_TRANSFER) {
        resp = await postExternalTransferRequest({ body: this.complexFundingPayload });
      } else {
        resp = await postComplexFundingRequest({ body: this.complexFundingPayload });
      }
      if (this.fundingDocuments?.length > 0) {
        await this.updateFundingRequestWithDocuments(resp.id);
      }
      if (!(canSetStagedTransfer(this.activeClient.client, this.selectedRequestSubtype?.id, this.legalEntityTransfersFeatureFlag))) {
        await this.startWorkflow(resp.id);
      }
      this.finalize();
    },
    async uploadDocuments() {
      const docIds = [];
      // check for documents first
      if (this.fundingDocuments?.length > 0) {
        const uploadResp = await uploadDocuments({
          documents: this.fundingDocuments,
          client: this.activeClient.client,
          docType: FUNDING_DOCUMENT_TYPE,
          workFlow: FUNDING_WORKFLOW,
          documentSource: this.documentSource.Manual,
        });
        // update funding request with document ids
        docIds.push(...uploadResp.map((r) => r.value.doc_id).filter((e) => e !== undefined));
      }

      return docIds;
    },
    async startWorkflow(requestId) {
      // create + initiate workflow after documents done uploading
      const workflow = await createWorkflow({
        body: {
          funding_id: requestId,
          funding_subType: this.selectedRequestSubtype.id,
          reason: 'initiate',
          adjust_down: false,
        },
      });
      await this.initiateWorkflow(workflow.id, 'funding');
    },
    finalize() {
      if (canSetStagedTransfer(this.activeClient.client, this.selectedRequestSubtype?.id, this.legalEntityTransfersFeatureFlag)) {
        this.openProcessingModal = false;
        // refresh staged transfers in store
        this.$store.dispatch('setStagedExternalTransfers', { clientId: this.activeClient.client.id }).then(() => {
          this.$router.push({ path: `/clients/${this.$route.params.clientID}/funding-v2/added-transfers` });
          this.clearStore();
        });
      } else {
        this.showToast();
        setTimeout(() => { // to allow toast to show for a couple seconds
          this.openProcessingModal = false;
          this.$router.push({ path: `/clients/${this.$route.params.clientID}/funding-v2` });
          this.clearStore();
        }, 3000);
      }
    },
    showToast() {
      this.$bvToast.show('success-toast');
    },
    clearStore() {
      this.$store.dispatch('setValidatedRequest', {});
      this.$store.dispatch('setActiveRequest', {});
      this.$store.dispatch('setWarningMessage', {});
      this.$store.dispatch('setFundingDocuments', []);
      this.$store.dispatch('setEditMode', false);
    },
    async cancelWorkflow() {
      // Cancel related workflows
      try {
        const resp = await fetchWorkflowBySystematicId(this.activeRequest.funding_id);
        const workflows = await Promise.all(resp.map((e) => fetchWorkflowDetails(e.workflow)));
        // Should only have one active workflow associated
        const inProgressWorkflows = workflows.find((wf) => ['in_progress', 'needs_attention', 'process_error'].includes(wf.status));
        if (inProgressWorkflows) {
          await cancelFundingWorkflow(inProgressWorkflows.id);
        }
      } catch (err) {
        throw new Error(`Fail to cancel funding workflow with funding request ${this.activeRequest.funding_id}`, err);
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
