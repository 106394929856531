<template>
  <IpsGroupsTable
    @view-ips-group="goToIpsGroupDetailPage($event)"
    :canDeleteIpsGroup="false"
    :canCancelIpsGroup="false"
  />
</template>

<script>
export default {
  name: 'IpsGroupsTab',
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    goToIpsGroupDetailPage(ipsGroupGuid) {
      this.$router.push({ name: 'IpsGroupPage', params: { ipsGroupGuid } });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
