<template>
  <v-flex xs6>
    <div v-if="!hasFirmStepPreferences" class="form-label strong-text mb-3">
      {{WORKFLOW.STEP_DISPLAY_NAMES[stepName]}}
    </div>
    <div v-else class="form-label strong-text mb-3">
      {{stepNameVerbose}}
    </div>
    <div v-if="!hasFirmStepPreferences">
      {{WORKFLOW.STEP_DESCRIPTIONS[stepName]}}
    </div>
    <div v-else>
      {{stepDescription}}
    </div>
  </v-flex>
</template>

<script>
import { WORKFLOW, WORKFLOWS_WITH_FIRM_STEP_PREFERENCES } from 'Services/constants';
import api from 'Services/apiV2';
import { fetchCMWorkflowFlags } from 'Services/api/Workflows.js';

export default {
  name: 'WorkflowStepName',
  props: {
    step: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      stepDescription: '',
      stepNameVerbose: '',
      WORKFLOW,
      stepName: '',
    };
  },
  async mounted() {
    this.stepName = this.step.name;
    const workflowStep = await this.getWorkflowStepFromPreferencesTable();
    if (workflowStep) {
      this.stepDescription = workflowStep.step_description;
      this.stepNameVerbose = workflowStep.step_name_verbose;
    }
    if (this.step.name === 'change_request') { // display proper info when FATCA renewal is included
      const flags = await fetchCMWorkflowFlags(this.workflow.id);
      if (flags && flags.is_fatca) {
        this.stepName = 'change_request_fatca';
      }
    }
  },
  methods: {
    async refreshWorkflow() {
      this.workflowID = this.$route.params.workflowID;
      this.$store.dispatch('fetchWorkflowV2Steps', this.workflowID);
    },
    async getWorkflowStepFromPreferencesTable() {
      const workflowType = this.$store.state.workflow.selectedWorkflow.type;
      const workflowSteps = await api.get(`/workflow_step_firm_preferences/?workflow_type=${workflowType}&limit=100`);
      const stepName = this.step.name;

      const currentStep = workflowSteps.results.find((ws) => ws.step_name === stepName);
      return currentStep;
    },
  },
  computed: {
    workflow() {
      return this.$store.state.workflow.selectedWorkflow;
    },
    hasFirmStepPreferences() {
      return WORKFLOWS_WITH_FIRM_STEP_PREFERENCES.includes(this.workflow.type);
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn--floating.v-btn--small  {
  height: 24px !important;
  width: 24px !important;
}

</style>
