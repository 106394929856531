<template>
  <v-dialog :value="isOpen" max-height="60rem" max-width="60rem">
    <v-card class="pa-5" max-height="60rem" max-width="60rem">

      <PasFab class="close-btn">
        <v-icon @click="$emit('close')" class="close-icon">close</v-icon>
      </PasFab>

      <v-card-title v-if="action === 'edit'" class="headline pl-0" :style="{ justifyContent: 'center' }">
        Edit Relationship
      </v-card-title>

      <v-card-title v-if="action === 'add'" class="headline pl-0" :style="{ justifyContent: 'center' }">
        Add New Relationship
      </v-card-title>

      <v-form @submit.prevent ref="form" lazy-validation>

        <v-card-text v-if="action === 'edit'" class="mb-3 pl-0" :style="{ textAlign: 'center' }">
          To update the relationship, select from the list below
        </v-card-text>
        <v-card-text v-if="action === 'add'" class="mb-3 pl-0" :style="{ textAlign: 'center' }">
          To set up a client’s relationship, select the name of the client you want to add a relationship to.
          If the person is not yet a client, you can
          <span @click="openNewClientModal()" class="action-link underline">add a new client.</span>

        </v-card-text>
        <v-layout row>
          <v-flex xs5>
            <div class="strong-text pb-2">Client List</div>
            <pas-autocomplete
              v-model="secondaryClient"
              :items="clients"
              :rules="[v => !!v || 'Please select a name from the list']"
              :prependInnerIcon="`search`"
              :placeholder="`Type the name of the client...`"
              v-if="action === 'add'"
              :returnObject='true'
            />
            <pas-autocomplete
              :value="relationship.client_2_id"
              :items="clients"
              :prependInnerIcon="`search`"
              :appendIcon="``"
              v-if="action === 'edit'"
              :disabled="true"
            />
          </v-flex>
          <v-flex text-xs-center mt-4 pt-3 xs3>
            <span v-if="client.id">{{client.displayName}}</span>
            <span v-else>{{activeClient ? `is ${activeClient.display_name}'s` : ''}}</span>
          </v-flex>
          <v-flex xs4>
            <div class="strong-text pb-2">Relationship Type</div>
            <pas-autocomplete
              :items="relations"
              v-model="relation"
              :rules="[v => !!v || 'Please select relationship type']"
              :placeholder="`Select a relationship type...`"
              :smallChips="true"
              :deletableChips="true"
              :returnObject='true'
            />
          </v-flex>
        </v-layout>
        <v-layout justify-end v-if="showTrustedContactPerson()">
          <v-flex xs4>
            <v-layout align-center>
              <v-flex>
                <v-switch
                  class="switch"
                  color="primary"
                  :disabled="!selectedRelationshipClientId || isTcpSwitchLoading"
                  false-value="N"
                  true-value="Y"
                  v-model="addAsTcp"
                  :loading="isTcpSwitchLoading"
                  @change="handleAddAsTcp"
                />
              </v-flex>
              <v-flex>
                <span class="body-2">Set as Trusted Contact Person</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout v-if="hasFeatureFlag('client_maintenance')">
          <div class="spouse-warning strong-text mt-3 pl-0">For Spouse or Common-Law, please use the <router-link class="action-link underline" :to="openClientDetailsPage()">Client Details page</router-link></div>
        </v-layout>
        <div class="sx12 mt-2"><span class="error--text">{{errorText}}</span></div>
        <v-card-actions class="pr-0 mt-4">
          <v-spacer />
          <pas-button
            class="primary-btn wide-btn"
            @click="action === 'edit' ? edit() : add()"
            :processing="processing"
          > {{ action === 'edit' ? 'Save' : 'Add'}}</pas-button>
        </v-card-actions>
      </v-form>
      <add-new-client
        :isOpen="newClientModalOpen && !hasFeatureFlag('client_data_hierarchy')"
        @close="newClientModalOpen = false"
      />
      <AddNewClientNoCall
        :isOpen="newClientModalOpen && hasFeatureFlag('client_data_hierarchy')"
        @close="newClientModalOpen = false"
      />
      <MessageDialog
        @ok="CDHIneligibleDialog = false"
        :isOpen="CDHIneligibleDialog"
        :width="'40%'"
      >
        <template v-slot:header>Cannot add clients</template>
        {{cantAddClientsMsg}}
        <template
          v-slot:btn
        >Okay</template>
      </MessageDialog>
    </v-card>
    <trusted-contact-person-info
      :tcpId="String(selectedRelationshipClientId)"
      v-if="isTcpModalOpen"
      @close="handleTcpModalClose"
    />
  </v-dialog>
</template>

<script>

import {
  RELATIONS,
  RELATIONSMAP,
  TRUSTED_CONTACT_PERSON,
  WARNING_MESSAGES,
} from 'Services/constants';
import AddNewClientNoCall from 'Components/Modals/AddNewClientNoCall';
import TrustedContactPersonInfo from 'Components/Modals/TrustedContactPersonInfo';
import AddNewClient from 'Components/Modals/AddNewClient';
import MessageDialog from 'Components/Shared/MessageDialog';
import { modal } from 'Services/mixins';
import { mapGetters } from 'vuex';
import apiv2 from 'Services/apiV2';

const TCP_DEFAULT = TRUSTED_CONTACT_PERSON.Unknown;

export default {
  name: 'create-new-relationship',
  components: {
    AddNewClient,
    AddNewClientNoCall,
    MessageDialog,
    TrustedContactPersonInfo,
  },
  props: {
    action: String,
    isOpen: {
      type: Boolean,
      default: false,
    },
    relationship: {
      type: Object,
      default() {
        return {};
      },
    },
    client: {
      type: Object,
      default() {
        return { id: null, displayName: '' };
      },
    },
  },
  mixins: [modal],
  data() {
    return {
      secondaryClient: '',
      relation: '',
      errorText: '',
      newClientModalOpen: false,
      processing: false,
      CDHIneligibleDialog: false,
      cantAddClientsMsg: WARNING_MESSAGES.cannot_add_clients,
      addAsTcp: TCP_DEFAULT,
      isTcpModalOpen: false,
      isTcpSwitchLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'hasFeatureFlag',
      'currentUser',
      'advisorTeams',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient.client;
    },
    existingRelationships() {
      return this.$store.state.clients.activeClient.relationships;
    },
    relations() {
      if (this.hasFeatureFlag('client_maintenance')) {
        return (Object.keys(RELATIONS).map((key) => ({ value: key, text: RELATIONS[key] })))
          .filter((item) => item.value !== RELATIONSMAP.CL && item.value !== RELATIONSMAP.SP);
      }

      return Object.keys(RELATIONS).map((key) => ({ value: key, text: RELATIONS[key] }));
    },
    relationsReversed() {
      const REL = {};
      Object.keys(RELATIONS).forEach((key) => REL[RELATIONS[key]] = key);
      return REL;
    },
    clients() {
      if (this.$store.state.clients.list[0] === 'loading') return [];

      return this.$store.state.clients.list
        .filter((client) => client.client_type === 'person' && client.id !== this.activeClient.id)
        .sort((clientA, clientB) => {
          const compareLastName = clientA.person.last_name?.localeCompare(clientB.person.last_name);
          const compareFirstName = clientA.person.first_name?.localeCompare(clientB.person.first_name);
          return compareLastName || compareFirstName;
        })
        .map((client) => {
          const { id, display_name } = client;
          const text = display_name ? `${display_name} (${id})` : '';

          return {
            text,
            value: id,
          };
        });
    },
    selectedRelationshipClientId() {
      return this.secondaryClient.value || this.relationship.client_2_id;
    },
  },
  watch: {
    isOpen() {
      if (this.action === 'edit' && this.relationship.relationship_type) {
        this.relation = this.relationsReversed[this.relationship.relationship_type];
      }
    },
    relationship() {
      this.addAsTcp = this.relationship.trusted_contact_person || TCP_DEFAULT;
    },
    secondaryClient() {
      this.addAsTcp = TCP_DEFAULT;
    },
  },
  methods: {
    validate() {
      this.errorText = '';
      if (!this.$refs.form.validate()) return false;
      if (this.activeClient.id === this.secondaryClient.value) {
        this.errorText = 'Can not create relationship with self';
        return false;
      }
      if (this.existingRelationships.some((rel) => rel.client_details[0] && rel.client_details[0].id === this.secondaryClient.value)) {
        this.errorText = 'Duplicate relationships not allowed.';
        return false;
      }
      return true;
    },
    handleResponses(section, type) {
      this.processing = false;
      let text = `Failed to ${section} relationship`;
      if (type === 'success') {
        text = `Relationship has been successfully ${section === 'delete' ? 'delet' : section}ed`;
        this.close();
      }
      this.$store.dispatch('setSnackbar', { text, type, topic: '' });
      this.$store.dispatch('flipSnackbarSwitch');
      this.$store.dispatch('fetchRelationships', this.activeClient.id);
    },
    add() {
      if (!this.validate()) return;
      this.processing = true;
      const client = this.client.id ? this.client : this.activeClient;
      const relationshipObj = {
        relationship: this.relation.value,
        view_accounts: false, // Defaulting to false for now TODO: Figure out how we want to handle this
        power_of_attorney: false, // Defaulting to false for now TODO: Figure out how we want to handle this
        trusted_contact_person: this.addAsTcp,
        client1: client.id,
        client2: this.secondaryClient.value,
      };
      const inverseRelationshipObj = {
        relationship: RELATIONSMAP[this.relation.value],
        view_accounts: false, // Defaulting to false for now TODO: Figure out how we want to handle this
        power_of_attorney: false, // Defaulting to false for now TODO: Figure out how we want to handle this
        client1: this.secondaryClient.value,
        client2: client.id,
      };
      this.$store.dispatch('createRelationshipPair', [relationshipObj, inverseRelationshipObj])
        .then((responses) => {
          const goodResponses = responses.filter((res) => res.id).length;
          if (goodResponses !== 2) throw Error('error adding relationship');
          this.handleResponses('add', 'success');
          if (this.client.id) this.$emit('done');
        })
        .catch((err) => this.handleResponses('add', 'fail'));
    },
    showTrustedContactPerson() {
      // Since you can now do this in Client maintenance, completely remove the TCP check box so that you can't add or update it here.
      return false;
    },
    openNewClientModal() {
      if (this.hasFeatureFlag('client_data_hierarchy') && !this.advisorTeams.length) {
        this.CDHIneligibleDialog = true;
        return;
      }
      this.newClientModalOpen = true;
    },
    openClientDetailsPage() {
      const clientId = this.activeClient.id;
      return `/clients/${clientId}/client-maintenance?page_key=relationship`;
    },
    edit() {
      const primaryObj = {
        id: this.relationship.relationship_id,
        obj: {
          relationship: this.relation.value,
          trusted_contact_person: this.addAsTcp,
        },
      };
      const inverseObj = {
        id: this.relationship.inverse_relationship_id,
        obj: { relationship: RELATIONSMAP[this.relation.value] },
      };
      this.processing = true;
      this.$store.dispatch('editRelationshipPair', [primaryObj, inverseObj])
        .then((responses) => {
          const goodResponses = responses.filter((res) => res.id).length;
          if (goodResponses !== 2) throw Error('error editing relationshipObj');
          this.handleResponses('edit', 'success');
        })
        .catch((err) => this.handleResponses('edit', 'fail'));
    },
    deleteRel() {

    },
    async handleAddAsTcp() {
      try {
        if (this.addAsTcp === TRUSTED_CONTACT_PERSON.Yes) {
          this.isTcpSwitchLoading = true;

          const { response: isEligible } = await apiv2.get(`/clients/${this.selectedRelationshipClientId}/can_be_trusted_contact_person/`);

          if (isEligible) {
            this.isTcpSwitchLoading = false;
          } else {
            this.isTcpModalOpen = true;
          }
        } else {
          this.isTcpSwitchLoading = false;
        }
      } catch (e) {
        console.error(e);
        this.$store.dispatch('setSnackbar', {
          text: 'Failed to check client\'s information',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    handleTcpModalClose(isInfoUpdated) {
      this.isTcpModalOpen = false;
      this.isTcpSwitchLoading = false;

      if (!isInfoUpdated) this.addAsTcp = TRUSTED_CONTACT_PERSON.No;
    },
  },
};
</script>

<style lang="scss" scoped>
  .spouse-warning {
    font-size: 18px !important;
  }
</style>
