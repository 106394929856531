export const CLIENT_DOCUMENT_MAX_FILESIZE = 20; // MB

export const CLIENT_DOCUMENT_TIMEOUT = 80000; // ms, default is 30000 ms

export const FIRMS = {
  fwp: 'fwp',
  har: 'harness',
  mil: 'milestone',
  har_fcc: 'harness-fcc',
  sam: 'samara',
  fwp_fcc: 'fwp-fcc',
  ofc: 'ofc',
  wwm: 'wwm',
  owa: 'owa',
  fim: 'fim',
  vcm: 'vcm',
  pic: 'pic',
  mwm: 'mwm',
  tsw: 'tsw',
  dsg: 'dsg',
  awi: 'awi',
};

export const D1G1T_INTEGRATION_DATA = {
  awi: {
    d1g1tPrefix: 'aretec',
    ssoEnabled: true,
  },
  dsg: {
    d1g1tPrefix: 'delisle',
    ssoEnabled: true,
  },
  fim: {
    // Not enabled in AC, for reference only
    d1g1tPrefix: null,
    ssoEnabled: false,
  },
  fwp: {
    // Not enabled in AC, for reference only
    d1g1tPrefix: null,
    ssoEnabled: false,
  },
  fwp_fcc: {
    d1g1tPrefix: 'fwp',
    ssoEnabled: true,
  },
  har: {
    // Not enabled in AC, for reference only
    d1g1tPrefix: null,
    ssoEnabled: false,
  },
  har_fcc: {
    d1g1tPrefix: 'harness',
    ssoEnabled: true,
  },
  mil: {
    // Not enabled in AC, for reference only
    d1g1tPrefix: null,
    ssoEnabled: false,
  },
  mwm: {
    d1g1tPrefix: 'milestone',
    ssoEnabled: true,
  },
  ofc: {
    d1g1tPrefix: 'oceanfront',
    ssoEnabled: true,
  },
  owa: {
    // Not enabled in AC, for reference only
    d1g1tPrefix: null,
    ssoEnabled: false,
  },
  pic: {
    // Not enabled in AC, for reference only
    d1g1tPrefix: null,
    ssoEnabled: false,
  },
  sam: {
    d1g1tPrefix: 'samara',
    ssoEnabled: true,
  },
  tsw: {
    // Not enabled in AC, for reference only
    d1g1tPrefix: null,
    ssoEnabled: false,
  },
  vcm: {
    d1g1tPrefix: 'verecan',
    ssoEnabled: true,
  },
  wwm: {
    d1g1tPrefix: 'westmount',
    ssoEnabled: true,
  },
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const PROVINCES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const ENVIRONMENTS = {
  local: 'local',
  dev: 'dev',
  staging: 'staging',
  demo: 'demo',
  production: 'production',
};

export const GENDERS = {
  none: '',
  male: 'Male',
  female: 'Female',
  other: 'Add your own answer',
  unknown: 'Rather not say',
};

export const FCC_GENDERS = {
  none: '',
  male: 'Male',
  female: 'Female',
  other: 'Add your own answer',
  unknown: 'Rather not say',
};

export const MARITALSTATUS = {
  single: 'Single',
  married: 'Married',
  separated: 'Separated',
  divorced: 'Divorced',
  widowed: 'Widowed',
  unknown: 'Unknown',
  'common-law': 'Common Law',
};

export const EQUIFAX = {
  VERIFICATION_METHODS: {
    NF: 'Not Found',
    0: 'Single',
    1: 'Dual',
    NV: 'Not Verified',
    M: 'Manual',
  },
};

export const CLIENT = {
  TYPES: {
    person: 'person',
    corporation: 'corporation',
    trust: 'trust',
    foundation: 'foundation',
    ipp: 'ipp',
    nonprofit: 'non-profit',
  },
};

export const CLIENTSTATUS = {
  prospect: 'Prospect',
  'Other Contact': 'Other Contact',
  professional: 'Professional',
  active: 'Active',
  inactive: 'Inactive',
};

export const LEGAL_ENTITIES = [
  'Corporation',
  'Foundation',
  'Trust',
  'IPP',
  'Non-Profit',
];

export const PHONETYPELABELS = {
  M: 'Mobile',
  O: 'Office',
  H: 'Home',
  F: 'Fax',
  U: 'Unknown',
};

export const RISKLABELS = [ // round risk score down to whole number
  'Risk Averse',
  'Risk Averse',
  'Risk Averse',
  'Cautious',
  'Cautious',
  'Neutral',
  'Neutral',
  'Assured',
  'Assured',
  'Risk Taker',
  'Risk Taker',
];

export const SYSTEMATIC_TYPES = [
  'Unknown',
  'Deposit (PAC)',
  'Withdrawal (SWP)',
  'Deposit (EFT)',
  'Withdrawal (EFT)',
];

export const SYSTEMATICSSTATUS = [
  'Created',
  'Processing',
  'Waiting on first transaction',
  'Active',
  'Error',
  'Stopped',
];

export const FREQUENCIES = {
  U: 'Unknown',
  W: 'Weekly',
  B: 'Bi-Weekly',
  N: 'Semi-Monthly',
  M: 'Monthly',
  Q: 'Quarterly',
  S: 'Semi-Annually',
  A: 'Annually',
  O: 'One-Time',
};

export const TAGICONS = {
  CAD: '/advisor_portal/img/icons/CAD.PNG',
  C: '/advisor_portal/img/icons/CAD.PNG',
  USD: '/advisor_portal/img/icons/USD.PNG',
  U: '/advisor_portal/img/icons/USD.PNG',
  TICK: '/advisor_portal/img/icons/tick.PNG',
};

export const WARNING_MESSAGES = {
  cannot_add_clients: 'You must be a member of an Advisor team in order to add new clients.',
  fcc_genders: 'Fidelity Clearing Canada only supports Male and Female genders on their client onboarding forms.',
};

export const PAY_TO_TYPE = {
  BANK: 'bank',
  INVESTMENT: 'investment',
};

export const CURRENCYMAP = {
  C: {
    currency: 'CAD',
    locale: 'en-CA',
  },
  U: {
    currency: 'USD',
    locale: 'en-US',
  },
  CAD: {
    currency: 'CAD',
    locale: 'en-CA',
  },
  USD: {
    currency: 'USD',
    locale: 'en-US',
  },
};

export const JURISDICTIONS = [
  'FD',
  'BC',
  'ON',
  'QC',
  'AB',
  'MB',
  'NL',
  'NS',
  'NB',
  'PE',
  'SK',
  'NU',
  'NT',
  'YT',
];

export const JURISDICTIONS_FCC = {
  LRSP: ['FD'],
  LIRA: ['BC', 'ON', 'QC', 'AB', 'MB', 'NL', 'NS', 'NB', 'SK'],
  LIF: ['FD', 'BC', 'ON', 'QC', 'AB', 'MB', 'NL', 'NS', 'NB'],
  LRIF: ['NL'],
  PRIF: ['SK'],
};

export const MAX_ACCOUNTS_UNDER_RAP = 9;

export const ACCOUNTGROUP = [
  'Unknown',
  'Cash',
  'RRSP',
  'TFSA',
  'RESP',
  'RRIF',
  'Margin Long',
  'Margin Short',
  'Margin Short Hedge',
  'COD/DAP',
];

export const ACCOUNTTYPE = [
  'Unknown',
  'Cash-P',
  'Cash-PML',
  'Cash-PMS',
  'Cash-PMSH',
  'Cash-J',
  'Cash-JML',
  'Cash-JMS',
  'Cash-JMSH',
  'Cash-C',
  'Cash-CML',
  'Cash-CMS',
  'Cash-CMSH',
  'RRSP',
  'RRSP-S',
  'LRSP',
  'LIRA',
  'RESP-F',
  'RESP-I',
  'RRIF',
  'RRIF-S',
  'PRIF',
  'LRIF',
  'LIF',
  'TFSA',
  'CASH-ITF',
  'CASH-ITF-J',
  'RDSP',
  'FHSA',
];

export const REGISTERED_ACCOUNT_TYPES = [
  'RRSP',
  'RRSP-S',
  'SPOUSAL RRSP',
  'LRSP',
  'LIRA',
  'RESP-F',
  'RESP-I',
  'RRIF',
  'RRIF-S',
  'SPOUSAL RRIF',
  'PRIF',
  'LRIF',
  'LIF',
  'TFSA',
  'FHSA',
];

export const BASE_ACCOUNT_TYPES = [
  'CASH',
  'CASH ITF',
  'RRSP',
  'TFSA',
  'RESP',
  'RRIF',
  'LIF',
  'LRIF',
  'PRIF',
  'LRSP',
  'LIRA',
  'JOINT CASH',
  'JOINT CASH ITF',
  'SPOUSAL RRSP',
  'SPOUSAL RRIF',
  'RDSP',
  'FHSA',
];

export const ACCOUNT_TYPE_LONG = [
  'Unknown',
  'Personal Cash',
  'Personal Margin Long',
  'Personal Margin Short',
  'Personal Margin Short Hedge',
  'Joint Cash',
  'Joint Margin Long',
  'Joint Margin Short',
  'Joint Margin Short Hedge',
  'Corporate Cash',
  'Corporate Margin Long',
  'Corporate Margin Short',
  'Corporate Margin Short Hedge',
  'Registered Retirement Savings Plan',
  'Spousal Registered Retirement Savings Plan',
  'Locked-In Registered Retirement Savings Plan',
  'Locked-In Retirement Account',
  'Family Registered Education Savings Plan',
  'Individual Registered Education Savings Plan',
  'Registered Retirement Income Fund',
  'Spousal Registered Retirement Income Fund',
  'Prescribed Registered Retirement Income Fund',
  'Locked-In Registed Retirement Income Fund',
  'Life Income Fund',
  'Tax-Free Savings Accounts',
  'Personal Cash In Trust For Account',
  'Joint Cash In Trust For Account',
];

export const ACCOUNT_TYPE_EXCLUDED_EXTERNAL_TRANSFER_LONG = [
  'Unknown',
  'Personal Margin Long',
  'Personal Margin Short',
  'Personal Margin Short Hedge',
  'Joint Margin Long',
  'Joint Margin Short',
  'Joint Margin Short Hedge',
  'Corporate Cash',
  'Corporate Margin Long',
  'Corporate Margin Short',
  'Corporate Margin Short Hedge',
];

export const EXTERNAL_TRANSFER_TO_ACCOUNT_TYPE_LIST_FOR_EXTERNAL_ACCOUNT_TYPE = {
  1: ['1', '2', '3', '4'], // Personal Cash
  5: ['5', '6', '7', '8'], // Joint Cash
  9: ['9', '10', '11', '12'], // Corporate Cash
  // All others should default to filtering for same account type.
};

export const ACCOUNT_SUB_TYPE = {
  0: 'None',
  R: 'RRSP',
  S: 'Spousal RRSP',
  L: 'LRSP',
  F: 'RRIF',
  N: 'RRIF',
  P: 'PRIF',
  T: 'Spousal RRIF',
  Z: 'LRIF',
  X: 'LIF',
  I: 'IPP',
  E: 'Family RESP',
  V: 'Individual RESP',
  W: 'TFSA',
  Q: 'SME',
  B: 'FHSA',
};

export const ACCOUNT_ELIGIBILITY_AGE = {
  LIF_LRIF_RRIF: 50,
  RRSP_LRSP: 71,
};

export const PORTTYPE = {
  0: 'Unknown',
  PA: 'discretionary',
  PN: 'non-discretionary',
};

export const EMPLOYEETYPE = ['Client', 'Pro', 'Employee'];

export const EMPLOYMENTSTATUS = {
  0: 'Employed',
  2: 'Self-employed',
  3: 'Unemployed',
  4: 'Retired',
  5: 'Student',
  6: 'Stay-at-home parent',
  7: 'Homemaker',
};

export const INCOME_SOURCE = {
  'Salary Income': 'Paid by employer',
  'Self-employment Income': 'Self-employed',
  'Pension Income / CPP': 'Pension or government income',
  'Investment Income': 'Investment or savings',
  Other: 'Other',
};

export const EMPLOYMENTINDUSTRIES = [
  'Accommodation and Food Services',
  'Administrative and Support',
  'Waste Management and Remediation Services',
  'Arts',
  'Entertainment and Recreation',
  'Construction',
  'Educational Services',
  'Finance and Insurance',
  'Health Care and Social Assistance',
  'Information and Cultural Industries',
  'Management of Companies and Enterprises',
  'Manufacturing',
  'Professional, Scientific, and Technical Services',
  'Public Administration',
  'Real Estate and Rental and Leasing',
  'Retail Trade',
  'Transportation and Warehousing',
  'Utilities',
  'Wholesale Trade',
  'Agriculture, Forestry, Fishing and Hunting',
  'Mining, Quarrying, Oil and Gas Extraction',
];

export const RAPCODE = {
  0: 'Unknown',
  A: 'Cash CAD [A]',
  B: 'Cash USD [B]',
  C: 'COD/DAP CAD [C]',
  D: 'COD/DAP USD [D]',
  E: 'Margin Long CAD [E]',
  F: 'Margin Long USD [F]',
  G: 'Margin Short CAD [G]',
  H: 'Margin Short USD [H]',
  I: 'FHSA',
  Q: 'RESP CAD [Q]',
  R: 'Margin Short Hedge USD [R]',
  S: 'RRSP',
  T: 'RRIF',
  V: 'TFSA',
  X: 'Margin Short Hedge USD [X]',
  PA: 'SMA Cash CAD [PA]',
  PB: 'SMA Cash USD [PB]',
  PE: 'SMA Margin Long CAD [PE]',
  PF: 'SMA Margin Long USD [PF]',
  PG: 'SMA Margin Short CAD [PG]',
  PH: 'SMA Margin Short USD [PH]',
  PS: 'SMA RRSP [PS]',
  PT: 'SMA RRIF [PT]',
  PQ: 'SMA RESP [PQ]',
  PV: 'SMA TFSA [PV]',
};

export const JURIS = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Northwest Territories': 'NT',
  'Nova Scotia': 'NS',
  Nunavut: 'NU',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  Quebec: 'QC',
  Saskatchewan: 'SK',
  Yukon: 'YT',
  Federal: 'FD',
  NA: 'Not Applicable',
};

export const ACCOUNT_STATUS = {
  T: 'Temporary',
  O: 'Open',
  C: 'Closed',
  S: 'Suspended',
};

export const TRILOGY_REP_CODES = [
  '9H12',
  '9H14',
  '9H15',
  '9H16',
];

export const LAINCHBURY_REP_CODES = [
  '9H17',
];

export const LAKESHORE_REP_CODES = [
  '9H18',
  '9H19',
];

export const NICOLL_REP_CODES = [
  '9H20',
  '9H21',
];

export const GAIT_REP_CODES = [
  '9H22',
  '9H23',
];

export const ICP_MANDATENAMES = {
  B: 'B-JARISLOWSKY FRASER',
  BG: 'BG-Beutel Goodman',
  ET: 'ET-COMPLETE CANACCORD ETF',
  MA: 'MA-MAWER INVESTMENTS',
  S: 'S-GUARDIAN CAPITAL',
};

export const RELATIONS = {
  // FA: 'Father',
  // MO: 'Mother',
  // DA: 'Daughter',
  // SO: 'Son',
  PA: 'Parent',
  CH: 'Child',
  // AU: 'Aunt',
  // UN: 'Uncle',
  // NI: 'Niece',
  // NE: 'Nephew',
  SC: 'Siblings Child',
  PS: 'Parents Sibling',
  // HU: 'Husband',
  // WI: 'Wife',
  SP: 'Spouse',
  CO: 'Cousin',
  // BR: 'Brother',
  // SI: 'Sister',
  SB: 'Sibling',
  FR: 'Friend',
  CL: 'Common Law',
  GP: 'Grandparent',
  GC: 'Grandchild',
  ES: 'Ex-Spouse',
  SL: 'Sibling-in-law',
  PN: 'Partner',
  PR: 'Professional',
  LY: 'Lawyer',
  AC: 'Accountant',
  CT: 'Client',
  AS: 'Admin Assistant',
  EM: 'Employee',
  EP: 'Employer',
  AD: 'Auditor',
  BK: 'Banker',
  BP: 'Business Partner',
  Br: 'Business Relationship',
  Sc: 'Stepchild',
  Sp: 'Stepparent',
  TE: 'Tax Expert',
  Ct: 'Consultant',
  DO: 'Donor',
  DE: 'Donee',
  NT: 'Notary',
  OT: 'Other',
};

export const RELATIONSMAP = {
  PA: 'CH', /* FA/MO : DA/SP */
  CH: 'PA', /* DA/SP : FA/MO */
  SC: 'PS', /* AU/UN : NI/NE */
  PS: 'SC', /* NI/NE : AU/UN */
  SP: 'SP', /* HU/WI : WI/HU */
  CO: 'CO',
  CL: 'CL',
  SB: 'SB', /* BR/SI : SI/BR */
  FR: 'FR',
  ES: 'ES', /* Ex-HU/Ex-WI : Ex-WI/Ex-HU */
  GC: 'GP',
  GP: 'GC',
  SL: 'SL',
  PN: 'PN',
  PR: 'CT',
  LY: 'CT',
  AC: 'CT',
  CT: 'PR',
  OT: 'OT',
  AS: 'EP',
  EM: 'EP',
  EP: 'EM',
  AD: 'CT',
  BK: 'CT',
  BP: 'BP',
  Br: 'Br',
  Sc: 'Sp',
  Sp: 'Sc',
  TE: 'CT',
  Ct: 'CT',
  DO: 'DE',
  DE: 'DO',
  NT: 'CT',
};

export const CORPORATERELATIONS = [
  'Beneficial Owner',
  'Authorized Signatory',
  'Trading Authority',
  'Secretary',
  'Director',
  'Guarantor',
];

export const ACCOUNT_TRANSFER_METHODS = [
  'Unknown',
  'In-Kind',
  'In-Cash',
  'Mixed',
  'Partial',
];

export const ATON_TRANSFER = {
  METHODS: {
    K: 'In-Kind',
    C: 'In-Cash',
    M: 'Mixed',
  },
  STATUS: {
    ACC: 'Accepted',
    COMP: 'Completed',
    CONF: 'Confirmed',
    CONP: 'Confirmed pending',
    DEL: 'Deleted',
    INIT: 'Initial',
    NEW: 'New',
    REJ: 'Rejected',
    RESC: 'Rescinded',
    RET: 'Returned',
    UNC: 'Unconfirmed',
  },
};

export const IACODES = [
  '8P00',
  '8P01',
  '8P02',
  '8P03',
];

export const TRANSFERSTATUS = [
  'Staged',
  '1',
  '2',
  '3',
  '4',
  'Completed',
];

export const JOINTTYPES = [
  'Unknown',
  'Tenants in Common',
  'Joint Tenants with Right of Survivorship',
];

export const FILE_TYPES = [
  'pdf',
  'doc',
  'jpeg',
  'png',
  'xls',
  'other',
];

export const CLIENTACCOUNTSTATUSES = {
  unknown: 'unknown',
  active: 'active',
  inactive: 'inactive',
  prospect: 'prospect',
  coi: 'center of influence',
  deleted: 'deleted',
};

export const REPORTINGCURRENCIES = [
  'CAD',
  'USD',
];

export const LANGUAGES = {
  0: 'Unknown',
  EN: 'English',
  FR: 'French',
};

export const SHAREHOLDERCOMMS = [
  'Unknown',
  'None',
  'Some',
  'All',
];

export const BENEFICIALOWNERSHIPDISCS = [
  'Unknown',
  'I Do Not Object',
  'I Object',
];

export const BENEFICIARY_ROLES = {
  BN: 'Beneficiary',
  SA: 'Successor Annuitant',
  SH: 'Successor Holder',
};

export const DOCUMENT_TYPES = [
  'Other',
  'Discretionary Investment Management Agreement',
  'Account Opening Form',
  'Canaccord RSP Application',
  'Canaccord RIF Application',
  'Canaccord TFSA Application',
  'ICPM Disclaimer',
  'W8-Ben',
  'W9',
  'Certificate of Completion',
  'Suitability Waiver',
  'Transfer Authorization Form',
  'External Account Statement',
  'Void Cheque or Bank Statement',
  'Investment Policy Statement',
  'PAC Setup Form',
  'SWP Setup Form',
  'PAC Change Form',
  'SWP Change Form',
  'Photo ID',
  'Beneficiary Designation Form',
  'Locked In Addendum',
  'EFT Authorization Forms',
  'Grant Application Form',
  'Dataphile Gap Report',
  'Equifax Report',
  'Self-Directed Education Savings Plan',
  'Non-Discretionary Investment Management Agreement',
  'Management and Other Fees (Schedule D)',
  '54101 Form',
  'Supplemental Profile Form',
  'CIRO Forms',
  'Nominee Form',
  'Right of Survivorship',
  'Tenants in Common',
  'Referral Disclaimer',
  'Fee Arrangement Direction',
  'Pinnacle Online Client Access Registration Form',
  'Guarantee Form',
  'No Seal Letter',
  'Corporate Resolution',
  'Trading Authorization',
  'Full Authorization',
  'Corporate Account Information Form',
  'Managed Account Group Setup Instruction',
  'RC520 Form',
  'RC518 Form',
];

export const DOCUMENT_DESCRIPTIONS = {
  Unknown: 'N/A',
  'Discretionary Investment Management Agreement': 'A formal arrangement between an investment advisor and an investor stipulating the terms under which the adviser is authorized to act on behalf of the investor to manage the assets listed in the agreement',
  'Account Opening Form': 'Account application form',
  'Canaccord RSP Application': 'RRSP Application Form for Canaccord',
  'Canaccord RIF Application': 'RRIF Application Form for Canaccord',
  'Canaccord TFSA Application': 'TFSA Application Form for Canaccord',
  'ICPM Disclaimer': 'ICPM Disclaimer',
  'W8-Ben': 'Certificate of Foreign Status of Beneficial Owner for U.S. Tax Withholding, is used by a foreign person to establish both foreign status and beneficial ownership, and to claim income tax treaty benefits with respect to income other than compensation for personal services.',
  W9: 'Request for Taxpayer Identification Number and Certification',
  'Certificate of Completion': 'Record of signing events for account application',
  'Suitability Waiver': 'Suitability waiver',
  'Transfer Authorization Form': 'Transfer Authorization Form for an account with another institution',
  'External Account Statement': 'Account statement of investment account at relinquishing institution',
  'Void Cheque or Bank Statement': 'Void Cheque for bank account provided by the client',
  'Investment Policy Statement': 'N/A',
  'PAC Setup Form': 'PAC Setup Form',
  'SWP Setup Form': 'SWP Setup Form',
  'PAC Change Form': 'PAC Change Request Form',
  'SWP Change Form': 'SWP Change Request Form',
  'Photo ID': 'Photo ID',
  'Beneficiary Designation Form': 'Beneficiary Designation Form',
  'Locked In Addendum': 'Locked In Addendum',
  'EFT Authorization Forms': 'EFT Authorization Forms',
  'Grant Application Form': 'Grant Application Form',
  'Dataphile Gap Report': 'Dataphile Gap Report',
  'Equifax Report': 'Equifax Report',
  'Self-Directed Education Savings Plan': 'Self-Directed Education Savings Plan',
  'Non-Discretionary Investment Management Agreement': 'Non-Discretionary Investment Management Agreement',
  'Harness: Management and Other Fees (Schedule D)': 'Harness: Management and Other Fees (Schedule D)',
  '54101 Form': 'National Instrument 54-101 Communication with Benefical Owners of Securities of a Reporting Issuer - Explanation to Clients',
  'Supplemental Profile Form': 'Account Profile - Supplemental',
  'CIRO Forms': 'Brochures from Canadian Investment Regulatory Organization',
  'Nominee Form': 'Nominee Account Agreement',
  'Right of Survivorship': 'Account Agreement for Two Persons - With Right of Survivotship',
  'Tenants in Common': 'Account Agreement for Two or More Persons - Assets to be Held in Common',
  'Referral Disclaimer': 'Harness - Disclosure regarding Shared Services Arrangement and Authorization regarding Disclosure of Personal Information',
  'Fee Arrangement Direction': 'Fee Arrangement Direction',
  'Pinnacle Online Client Access Registration Form': 'Pinnacle Online Client Access Registration Form',
  'Guarantee Form': 'Guarantee',
  'No Seal Letter': 'No Seal Letter',
  'Corporate Resolution': 'Corporate Resolution',
  'Trading Authorization': 'Trading Authorization (Trading Only)',
  'Full Authorization': 'Full Authorization',
  'Corporate Account Information Form': 'Account Information - Legal Entity',
  'Managed Account Group Setup Instruction': 'Managed Account Group Setup Instruction',
};

export const FILE_EXTENSIONS = [ // whitelist
  'txt',
  'png',
  'jpeg',
  'jpg',
  'gif',
  'pjpeg',
  'doc',
  'docx',
  'xml',
  'dotx',
  'pdf',
  'ppt',
  'pps',
  'ppsx',
  'xls',
  'xlsx',
  'wks',
  'xlr',
  'csv',
  'mp3',
];

export const DOCUMENT_SOURCE = {
  U: 'Unknown',
  M: 'Manual',
  W: 'Workflow',
};

export const DOCUMENT_CATEGORY_BY_DOCUMENT_TYPE = {
  0: 'Other',
  1: 'Regulatory Documents',
  2: 'Account Opening Documents',
  3: 'Account Opening Documents',
  4: 'Account Opening Documents',
  5: 'Account Opening Documents',
  6: 'Regulatory Documents',
  7: 'Regulatory Documents',
  8: 'Regulatory Documents',
  9: 'Regulatory Documents',
  10: 'Regulatory Documents',
  11: 'Transfer-In',
  12: 'Transfer-In',
  13: 'EFT Authorization Form',
  14: 'IPS',
  15: 'Systematic',
  16: 'Systematic',
  17: 'Systematic',
  18: 'Systematic',
  19: 'Regulatory Documents',
  20: 'Account Opening Documents',
  21: 'Account Opening Documents',
  22: 'EFT Authorization Form',
  23: 'Account Opening Documents',
  24: 'Unknown',
  25: 'Regulatory Documents',
  26: 'Account Opening Documents',
  27: 'Regulatory Documents',
  28: 'Account Opening Documents',
  29: 'Account Opening Documents',
  30: 'Account Opening Documents',
  31: 'Regulatory Documents',
  32: 'Account Opening Documents',
  33: 'Account Opening Documents',
  34: 'Account Opening Documents',
  35: 'Account Opening Documents',
  36: 'Account Opening Documents',
  37: 'Account Opening Documents',
  38: 'Account Opening Documents',
  39: 'Account Opening Documents',
  40: 'Account Opening Documents',
  41: 'Account Opening Documents',
  42: 'Account Opening Documents',
  43: 'Account Opening Documents',
  44: 'Account Opening Documents',
  45: 'Account Opening Documents',
  46: 'Regulatory Documents',
  47: 'Regulatory Documents',
  48: 'Account Opening Documents',
  49: 'Account Opening Documents',
  50: 'Account Opening Documents',
  51: 'Account Opening Documents',
  52: 'Regulatory Documents',
  53: 'Account Opening Documents',
  54: 'Complex Funding Documents',
};

export const WORKFLOW_STATUS_MAP = {
  not_started: {
    color: '#d9ad4a',
    value: 100,
  },
  in_progress: {
    color: 'green',
    value: 50,
  },
  needs_attention: {
    color: 'red',
    value: 100,
  },
  process_error: {
    color: 'red',
    value: 100,
  },
  completed: {
    color: 'green',
    value: 100,
  },
  cancelled: {
    color: 'red',
    value: 100,
  },
  not_required: {
    color: 'green',
    value: 100,
  },
  pre_update_approval: {
    color: 'green',
    value: 100,
  },
};

export const IPSTYPES = {
  REBALANCING: 'RE',
  SECURITY_SELECTION: 'SS',
  RISK_MANAGEMENT: 'RM',
  REVIEWS: 'RV',
  PROXY_VOTING: {
    ALL: 'PVA',
    OURS: 'PVO',
    NON_STANDARD: 'PVN',
    NONE: 'PVX',
  },
};

export const ROLE_NAMES = {
  guest_user: 'Guest User',
  associate_pm: 'Associate PM',
  pm_team_member: 'PM Team Member',
  referral_advisor: 'Referral Advisor',
  advisor_team_member: 'Advisor Team Member',
  account_manager: 'Account Manager',
  operations_associate: 'Operations Associate',
  compliance: 'Compliance',
  portfolio_manager: 'Portfolio Manager',
  internal_team: 'Internal Team',
  external_team: 'External Team',
  super_admin: 'Super Admin',
  internal_user: 'Internal User',
  operations: 'Operations',
  ra_team_member: 'Referral Advisor Team Member',
  test_automation_user: 'Test Automation User',
  investment_advisor: 'Investment Advisor',
};

export const MODULE_NAMES = {
  client_type: 'Client Type',
  view_full_profile: 'View Full Profile',
  client_page: 'Client Page',
  operational_capabilities: 'Operational Capabilities',
  workflows: 'Workflows',
  my_clients: 'My Clients',
  client: 'Client',
  fee_management: 'Fee Management',
  new_relationships: 'New Relationships',
  basic_modules: 'Basic Modules',
  survey_engine: 'Survey Engine',
  dataphile: 'Dataphile',
  initiate_client_onboarding: 'Client Onboarding',
  dashboard: 'Dashboard',
  basic_functionalities: 'Basic Functionalities',
  user_management: 'User Management',
};

export const SUBMODULE_NAMES = {
  account_opening: 'Account Opening',
  account_transfers: 'Account Transfers',
  advisor_aggregated_activities: 'Advisor Aggregated Activities',
  advisor_team_administration: 'Advisor Team Administration',
  bank_account_setup: 'Bank Account Setup',
  bank_accounts: 'Bank Accounts',
  basic_client_information: 'Basic Client Information',
  basic_sub_module: 'Basic Functionalities',
  client_relationships: 'Client Relationships',
  client_details: 'Client Details',
  client_documents: 'Client Documents',
  client_portal_document_repository: 'Document Repository',
  client_profile_page: 'Client Profile Page',
  external_account_transfer: 'External Account Transfer',
  fee_management: 'Fee Management',
  financial_profile: 'Financial Profile',
  funds_and_systematic_transfers: 'Funds Transfers and Systemic Transfers',
  generate_ips: 'Generate IPS',
  generate_transfer_documents: 'Generate Transfer Documents',
  initiate_onboarding: 'Initiate Onboarding',
  internal_transfers: 'Internal Transfers',
  investment_accounts: 'Investment Accounts',
  ips_signing: 'IPS Signing',
  my_clients: 'My Clients',
  my_clients_basic_information: 'My Clients Basic Information',
  new_relationship: 'New Relationships',
  no_call_model: 'No Call Model',
  onboarding_workflow: 'Onboarding Workflow',
  open_account: 'Open Account',
  portfolio_management: 'Portfolio Management',
  profile_viewing: 'Profile Viewing',
  questionnaires_send_options: 'Questionnaire Send Options',
  referral_agreement_administration: 'Referral Agreement Administration',
  risk_profile: 'Risk Profile',
  set_up_systematic: 'Set Up Systematic',
  static_client_information: 'Static Client Information',
  survey_engine: 'Discovery Tool (Survey Engine)',
  systematic_setup_and_eft_setup: 'Systematic Setup & EFT Setup',
  transfer_account: 'Transfer Account (External Transfer In)',
  transfer_funds: 'Transfer Funds',
  view_business_and_direct_relationships: 'View Business and Direct Relationships',
  view_or_add_documents: 'View or Add Documents',
  workflows: 'Workflows',
  widgets: 'Widgets',
  basic: 'Basic',
  roles_and_permissions_manager: 'Roles and Permissions Manager',
  platform_users_manager: 'Platform Users Manager',
};

export const WORKFLOW = {
  TYPES: {
    account_opening: 'account_opening',
    annual_kyc: 'annual_kyc',
    bank_account_setup: 'bank_account_setup',
    client_maintenance: 'client_maintenance',
    client_onboarding: 'client_onboarding',
    client_onboarding_fcc_ip: 'client_onboarding_fcc_ip',
    client_onboarding_no_call: 'client_onboarding_no_call',
    corporate_onboarding: 'corporate_onboarding',
    eft_setup: 'eft_setup',
    external_account_transfer: 'external_account_transfer',
    funding: 'funding',
    ips_group: 'ips_group',
    ips_signing: 'ips_signing',
    money_movement_fcc_manual: 'money_movement_fcc_manual',
    supplemental: 'supplemental',
    supplemental_corporate_onboarding: 'supplemental_corporate_onboarding',
    systematic_setup: 'systematic_setup',
    fatca_repapering: 'fatca_repapering',
  },
  STATUSES: [
    'not_started', // yellow
    'in_progress', // half green half grey
    'needs_attention', // red
    'completed', // green
    'cancelled', // red
    'not_required', // green
  ],
  STATUS_NAMES: {
    not_started: 'Not Started', // yellow
    in_progress: 'In Progress', // half green half grey
    needs_attention: 'Needs Attention', // red
    completed: 'Completed', // green
    cancelled: 'Cancelled', // red
    process_error: 'Process Error',
    not_required: 'Not Required',
    pre_update_approval: 'Completed',
  },
  DISPLAY_NAMES: {
    account_opening: 'Account Opening',
    account_opening_fcc: 'Account Opening',
    annual_kyc: 'Annual KYC',
    bank_account_setup: 'Bank Account Setup',
    bank_account_setup_fcc: 'Bank Account Setup',
    cancelled: 'Cancelled',
    client_maintenance: 'Client Maintenance',
    client_onboarding: 'Client Onboarding',
    client_onboarding_fcc: 'Client Onboarding',
    client_onboarding_fcc_ip: 'Client Onboarding',
    client_onboarding_no_call: 'Client Onboarding No Call',
    client_onboarding_no_call_fcc: 'Client Onboarding No Call',
    completed: 'Completed',
    corporate_onboarding: 'Corporate Onboarding',
    eft_setup: 'EFT Setup',
    external_account_transfer: 'External Account Transfer',
    external_account_transfer_fcc: 'External Account Transfer',
    fintrac_repapering: 'Fintrac',
    funding: 'Funding',
    in_progress: 'In Progress',
    internal_transfers_fcc: 'FCC Internal Transfer',
    ips_group: 'IPS Group',
    ips_signing: 'IPS Signing',
    ips_workflow: 'IPS Generation',
    money_movement_fcc: 'Deposits and Withdrawals',
    money_movement_fcc_manual: 'Deposits and Withdrawals',
    needs_attention: 'Needs Attention',
    not_required: 'Not Required',
    not_started: 'Not Started',
    pre_update_approval: 'Completed',
    remediation_repapering: 'Repapering Remediation',
    supplemental: 'Supplemental',
    supplemental_corporate_onboarding: 'Supplemental Corporate Onboarding',
    systematic_setup: 'Systematic Setup',
    fatca_repapering: 'FATCA Renewal',
  },
  STEP_DISPLAY_NAMES: {
    ac_document_processing: 'Document Processing',
    account_opening: 'Account Opening Questionnaire',
    advisor_approval: 'Portfolio Manager approval',
    advisor_signing: 'Advisor Signing',
    aml_check: 'AML check',
    change_request: 'Profile update',
    change_request_fatca: 'Profile update',
    client_info_confirmation: 'Client confirmation & tax information',
    client_info_validation: 'Client Information Validation',
    client_signing: 'Client signature',
    compliance_approval: 'Compliance approval',
    confirm_account_number: 'Confirm Account Number',
    confirm_transaction: 'Confirm Transaction',
    crs_check: 'Tax information review (CRS)',
    custodian_document_processing: 'Custodian Document Processing',
    custodian_processing: 'Process Request',
    datacapture: 'Onboarding survey',
    details_validation: 'Pre-process Validation',
    details_validation_complex_funding: 'Pre-process Validation',
    details_validation_manual_external_transfers: 'Pre-process Validation',
    details_validation_manual_funding: 'Pre-process Validation',
    details_validation_manual_internal_transfers: 'Pre-process Validation',
    document_generation: 'Document generation',
    review_and_approval: 'Review & Approval',
    document_generation_kyc: 'Document generation',
    document_processing: 'Custodian approval and processing',
    document_processing_kyc: 'Document processing',
    document_processing_manual: 'Document FCC Email Confirmation',
    equifax_check: 'Equifax check',
    fatca_check: 'Tax information review (FATCA)',
    fatca_crs_remediation: 'FATCA CRS Remediation',
    fcc_api_account_numbers: 'Account number generation',
    fcc_api_process_accounts: 'Custodian approval and processing',
    fcc_api_validation: 'Client details review',
    fcc_bank_account_processing: 'FCC Processing',
    fcc_doc_processing: 'FCC Document Processing',
    fcc_document_processing: 'Custodian approval and processing',
    fcc_payload: 'FCC Payload',
    fcc_process_request: 'Custodian approval and processing',
    fcc_processing: 'Account opening completed',
    fcc_processing_eat: 'FCC Processing',
    fcc_validation: 'Information Validation',
    fcc_validation_kyc: 'Client details review',
    initiate_surveys: 'Questionnaire generation',
    ips_details_validation: 'Details Validation',
    joint_account_check: 'Joint account review',
    manual_processing: 'Process Request',
    pinnacle_api: 'Pinnacle API',
    pinnacle_processing: 'Processing and sending docs to Pinnacle',
    pm_approval: 'PM Approval',
    portfolio_assignment: 'Portfolio assignment',
    receive_account_number: 'Receive Account Number',
    risk_questionnaire: 'Risk Questionnaire',
    send_to_client: 'Send To Client',
    supplemental: 'Supplemental',
    supplemental_check: 'Supplemental Check',
    trading_required: 'Trading Processing',
  },
  STEP_DESCRIPTIONS: {
    account_opening: 'This survey will gather the client’s personal details.',
    advisor_approval: 'We are currently reviewing the client\'s information. ',
    advisor_signing: 'Once you sign and submit the documents, they are completed and ready to be sent to the Back Office for processing.',
    aml_check: 'We are verifying the client’s identity. This step will be marked as completed once the client successfully passes the AML check.',
    annual_kyc: 'Your client has submitted an annual KYC review',
    change_request: 'A change has been submitted for your client.',
    change_request_fatca: 'The client has submitted a change request that includes FATCA renewal.',
    client_info_confirmation: 'An email has been sent to the client to view and confirm the information that has been entered for them. If the client disagrees with this information, you will receive a notification via email. If the client agrees, they will then proceed to complete their Account Opening & Risk Questionnaires.',
    client_info_validation: 'The system is verifying whether we have the right client information on file for this workflow',
    client_signing: 'The client has received their documents via DocuSign. Once the client has signed, this step will be marked as completed. ',
    compliance_approval: 'We are currently reviewing the clients information. ',
    confirm_account_number: 'Confirm Account Number',
    confirm_transaction: 'Transaction has been completed',
    crs_check: 'We are verifying if the client is a tax resident of Canada.',
    datacapture: 'Your client has completed their onboarding survey.',
    details_validation: 'Validating all the details with the custodian before submitting the final request.',
    document_generation: 'We are generating the documents required for the clients account opening package. Once generated, the client will receive their account opening package via DocuSign.',
    review_and_approval: 'We are currently reviewing the client\'s information and account opening documentation.',
    document_generation_kyc: 'We are generating the documents. Once generated, the client will receive their package via DocuSign.',
    document_processing: 'The client has signed their account opening documents and they have been sent off to the custodian.',
    document_processing_kyc: 'The client has signed their KYC documents and we are getting them from Docusign.',
    document_processing_manual: 'This step will ensure the documents required for this workflow have been successfully generated and sent to FCC via email.',
    equifax_check: 'We are verifying the client’s identity. This step will be marked as completed once the client successfully passes the Equifax AML check.',
    fatca_check: 'We are verifying if the client is a tax resident of the US and the additional documentation required if so. ',
    fatca_crs_remediation: 'This step checks if the FATCA CRS has been completed',
    fcc_api_account_numbers: 'The custodian is automatically generating account number(s) for the client. ',
    fcc_api_process_accounts: 'The clients\' accounts are in the process of being opened with the custodian. Once this step is marked as completed, the clients\' accounts are opened. ',
    fcc_api_validation: 'We are validating the clients\' details we have on file with our custodian. ',
    fcc_bank_account_processing: 'In this step, the bank account opening request is sent to FCC',
    fcc_doc_processing: 'The documents have been uploaded to AdvisorHub and are being sent to the custodian.',
    fcc_document_processing: 'Documents are been sent off to the custodian.',
    fcc_payload: 'Request validation with FCC and confirmation',
    fcc_process_request: 'The client\'s information is being updated with the custodian.',
    fcc_processing: 'The clients\' accounts are now open. ',
    fcc_processing_eat: 'The request is sent to the custodian.',
    fcc_validation: 'This step validates all the information with the custodian.',
    fcc_validation_kyc: 'We are validating the clients\' details we have on file with our custodian.',
    initiate_surveys: 'We are automatically generating the Account Opening & Risk Questionnaires for the client to complete.',
    ips_details_validation: 'Validating all client details necessary for IPS document generation.',
    joint_account_check: 'We are checking if the client is opening a joint account and if the secondary account owner has been onboarded.',
    pinnacle_api: 'Pinnacle API',
    pinnacle_processing: 'Processing and sending docs to Pinnacle',
    pm_approval: 'This step requires document review and approval. The documents have been sent to a member of your firm.',
    portfolio_assignment: 'We are currently reviewing the clients responses to recommend a portfolio and risk score.\n\nIf this step states \'Needs Attention\', a Portfolio Manager is required to speak with your client to recommend a portfolio.',
    receive_account_number: 'Receive Account Number',
    risk_questionnaire: 'This survey will help determine the client’s risk profile and overall risk score.',
    send_to_client: 'Send To Client',
    supplemental: 'This step will ensure that the supplemental client has on-boarded.',
    supplemental_check: 'This step will check if supplemental workflow needs to be initiated.',
    trading_required: 'Making a trade if current balance is insufficient',
  },
};

export const FCC_FORBIDDEN_DEPOSIT_ONE_TIME = ['23', '19', '20', '16'];

export const FCC_FORBIDDEN_DEPOSIT_RECURRING = ['23', '19', '20', '16'];

export const FCC_FORBIDDEN_WITHDRAWALS_ONE_TIME = [];

export const FCC_FORBIDDEN_WITHDRAWALS_RECURRING = ['16', '17', '18', '19', '20', '22', '23'];

export const FCC_FREQUENCIES = {
  B: 'Bi-Weekly',
  N: 'Semi-Monthly (1st-15th)',
  M: 'Monthly',
  Q: 'Quarterly',
  S: 'Semi-Annually',
  A: 'Annually',
  O: 'One-Time',
};

export const TRANSACTION_STATUSES = {
  'client-signature': 'Client Signature',
  'under review': 'Under Review',
  pending: 'Upcoming',
  processed: 'Completed',
  cancelled: 'Cancelled',
};

export const REQUEST_TYPES = {
  PAC: 'Deposits',
  SWP: 'Withdrawals',
  'EXTERNAL-TRANSFER': 'Transfers In',
};

export const ACCOUNT_TYPES = {
  '': [],
  Cash: [1, 2, 3, 4, 9, 10, 11, 12],
  'Joint Cash': [5, 6, 7, 8],
  'Cash-ITF': [25, 26],
  RRSP: [13],
  'Spousal RRSP': [14],
  LIRA: [16],
  LRSP: [15],
  RESP: [17, 18],
  RIF: [19, 21],
  'Spousal RIF': [20],
  LRIF: [22],
  LIF: [23],
  TFSA: [24],
  FHSA: [28],
};

export const TRUSTED_CONTACT_PERSON = {
  Unknown: '0',
  Yes: 'Y',
  No: 'N',
};

export const IP_INVITATION_STATUSES = {
  pending: 1,
  accepted: 2,
  expired: 3,
  invalid: 4,
};

export const WORKFLOWS_WITH_FIRM_STEP_PREFERENCES = [
  'funding',
  'fatca_repapering'
]
