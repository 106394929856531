export const AddHouseholdMemberFlow = { // sample flow for modal manager POC
  addNewClient: ['SelectClientType', 'ClientName'],
  addNewEntity: [],
  linkExistingClient: [],
};

export const HouseholdFlows = { // flow
  // stage
  createNewHousehold: ['EnterHouseholdName', 'AddAdvisorTeam', 'CreateHouseholdSuccess', 'AddHouseholdMember', 'SetPrimaryMember', 'AddMembersSuccess'], // modal
  SelectClientType: ['SelectClientType'],
  AddNewIndividual: ['IndividualStatus', 'IndividualName', 'IndividualContact', 'AssignIndividualToHousehold', 'CreateClientSuccess'],
  AddNewEntity: ['EntityType', 'EntityName', 'EntityContact', 'AssignEntityToHousehold', 'CreateClientSuccess'],
  AddNewIndividualFromHousehold: ['IndividualStatus', 'IndividualName', 'IndividualContact', 'AddHouseholdMember'],
  AddNewEntityFromHousehold: ['EntityType', 'EntityName', 'EntityContact', 'AddHouseholdMember'],
  LeaveProgressWarning: ['LeaveProgressWarning'],
  DeleteHousehold: ['DeleteHousehold'],
};

export const HouseholdMemberFlows = {
  SwitchHousehold: ['SwitchHousehold'],
  IpsExistedWarning: ['IpsExistedWarning'],
};

export const ClientFlows = { // flow
  // stage
  deleteClient: ['deleteClientModal'], // modal
  cannotDeleteWarning: ['cannotDeleteWarning'],
};

export const FATCARenewalFlows = {
  StartRenewal: ['ConfirmFATCARenewal', 'FATCAQuestionnaire'],
  UpdateName: ['FirstName', 'MiddleName', 'LastName'],
  UpdateCitizenships: ['Citizenships'],
  UpdateAddresses: ['ResidentialAddress', 'AddressConfirmation', 'MailingAddress'],
  UpdateTaxInfo: ['TaxResidency', 'ClaimTaxTreatyBenefits', 'ClaimSpecialRatesAndConditions', 'ClaimSpecialRatesAndConditionsDetails'],
};
